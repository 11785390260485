import React from 'react';
import RealTimeTranscriptionWhisper from './components/RealTimeTranscriptionWhisper';
import Auth from './components/Auth';

function App() {
  return (
    <div className="App">
      <Auth />
      <RealTimeTranscriptionWhisper />
    </div>
  );
}

export default App;
