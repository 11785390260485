import React, { useState } from 'react';
import pdfToText from 'react-pdftotext';

const PDFUpload = ({ onTranscriptGenerated }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      setIsUploading(true);
      setUploadProgress(0);

      try {
        const text = await pdfToText(file, (progress) => {
          setUploadProgress(Math.round(progress * 100));
        });

        // Process the extracted text
        const lines = text.split('\n');
        const transcript = lines.map((line, index) => ({
          id: Date.now() + index,
          text: line.trim(),
          timestamp: new Date().toISOString(),
          highlighted: false
        })).filter(item => item.text !== ''); // Remove empty lines

        onTranscriptGenerated(transcript);
      } catch (error) {
        console.error("Failed to extract text from PDF:", error);
        alert("Failed to extract text from the PDF. Please try again.");
      } finally {
        setIsUploading(false);
        setUploadProgress(0);
      }
    } else {
      alert("Please select a valid PDF file.");
    }
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <input
        type="file"
        accept="application/pdf"
        onChange={handleFileUpload}
        disabled={isUploading}
        style={{ display: 'none' }}
        id="pdf-upload-input"
      />
      <label htmlFor="pdf-upload-input" style={{
        padding: '10px',
        backgroundColor: isUploading ? 'gray' : 'blue',
        color: 'white',
        borderRadius: '5px',
        cursor: isUploading ? 'not-allowed' : 'pointer',
        display: 'inline-block'
      }}>
        {isUploading ? 'Uploading...' : 'Select PDF File'}
      </label>
      {isUploading && (
        <div style={{ marginTop: '10px' }}>
          <progress value={uploadProgress} max="100" style={{ width: '100%' }} />
          <p>{uploadProgress}% Uploaded</p>
        </div>
      )}
    </div>
  );
};

export default PDFUpload;