import React, { useState } from 'react';
import JSZip from 'jszip';
import { DOMParser } from 'xmldom';
import axios from 'axios';

const PowerPointUpload = ({ onTranscriptGenerated }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getTextFromNodes = (node, tagName, namespaceURI) => {
    let text = '';
    const textNodes = node.getElementsByTagNameNS(namespaceURI, tagName);
    for (let i = 0; i < textNodes.length; i++) {
      text += textNodes[i].textContent + ' ';
    }
    return text.trim();
  };

  const getTextFromPPTX = async (arrayBuffer) => {
    try {
      const zip = new JSZip();
      await zip.loadAsync(arrayBuffer);

      const aNamespace = "http://schemas.openxmlformats.org/drawingml/2006/main";
      let text = '';
      
      let slideIndex = 1;
      while (true) {
        const slideFile = zip.file(`ppt/slides/slide${slideIndex}.xml`);
        
        if (!slideFile) break;
        
        const slideXmlStr = await slideFile.async('text');
        
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(slideXmlStr, 'application/xml');
        
        text += getTextFromNodes(xmlDoc, "t", aNamespace) + '\n\n';
        
        slideIndex++;
      }

      return text.trim();
    } catch (err) {
      console.error('Error extracting text from PPTX:', err);
      throw new Error('Failed to extract text from PowerPoint file');
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsLoading(true);
    setErrorMessage('');

    try {
      const arrayBuffer = await file.arrayBuffer();
      const extractedText = await getTextFromPPTX(arrayBuffer);
        /*
      // Generate transcript using OpenAI API
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4o',
          messages: [
            { role: 'system', content: 'You are a helpful assistant that generates transcripts from PowerPoint content.' },
            { role: 'user', content: `Please generate a coherent transcript from the following PowerPoint content:\n\n${extractedText}` }
          ],
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );
*/
      //const generatedTranscript = response.data.choices[0].message.content.trim();
      onTranscriptGenerated(extractedText);
    } catch (error) {
      console.error('Error processing PowerPoint file:', error);
      setErrorMessage('Failed to process PowerPoint file. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <input
        type="file"
        accept=".pptx"
        onChange={handleFileUpload}
        disabled={isLoading}
      />
      {isLoading && <p>Processing PowerPoint file...</p>}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div>
  );
};

export default PowerPointUpload;