import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import 'katex/dist/katex.min.css';

const LatexRenderer = ({ content }) => {
  console.log(content);
  // Replace \[ with $$ and \] with $$ to ensure compatibility
  const processedText = content
    .replace(/\\\[/g, '$$$')
    .replace(/\\\]/g, '$$$')
    .replace(/\\\(/g, '$')
    .replace(/\\\)/g, '$');

  return (
    <div className="latex-renderer">
      <ReactMarkdown
        children={processedText}
        remarkPlugins={[remarkMath, remarkGfm]}
        rehypePlugins={[rehypeKatex, rehypeRaw]}
        components={{
          code({node, inline, className, children, ...props}) {
            const match = /language-(\w+)/.exec(className || '')
            return !inline && match ? (
              <SyntaxHighlighter
                children={String(children).replace(/\n$/, '')}
                style={tomorrow}
                language={match[1]}
                PreTag="div"
                {...props}
              />
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            )
          },
          table: ({node, ...props}) => (
            <table className="markdown-table" {...props} />
          ),
          td: ({node, ...props}) => (
            <td className="markdown-td" {...props} />
          ),
          th: ({node, ...props}) => (
            <th className="markdown-th" {...props} />
          ),
        }}
      />
      <style jsx global>{`
        .latex-renderer .markdown-table {
          border-collapse: collapse;
          margin: 15px 0;
          font-size: 0.9em;
          font-family: sans-serif;
          min-width: 400px;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        }
        .latex-renderer .markdown-table thead tr {
          background-color: blue;
          color: #ffffff;
          text-align: left;
        }
        .latex-renderer .markdown-th,
        .latex-renderer .markdown-td {
          padding: 12px 15px;
        }
        .latex-renderer .markdown-table tbody tr {
          border-bottom: 1px solid #dddddd;
        }
        .latex-renderer .markdown-table tbody tr:nth-of-type(even) {
          background-color: #f3f3f3;
        }
        .latex-renderer .markdown-table tbody tr:last-of-type {
          border-bottom: 2px solid blue;
        }
      `}</style>
    </div>
  );
};

export default LatexRenderer;