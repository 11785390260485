import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCJfEBW8jdEwhPwBhicMPBtvloNjxql83U",
  authDomain: "lecturer-5bc7d.firebaseapp.com",
  projectId: "lecturer-5bc7d",
  storageBucket: "lecturer-5bc7d.appspot.com",
  messagingSenderId: "743812459256",
  appId: "1:743812459256:web:2e1e6e7f2ce5b60b55690d",
  measurementId: "G-T64L8EBZ9Q"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };