import React from 'react';
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const Auth = () => {
  const [user] = useAuthState(auth);

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  };


  return (
    <div className="auth-container" style={{
      position: 'absolute',
      top: '50%', // Center vertically
      left: '50%', // Center horizontally
      transform: 'translate(-50%, -50%)', // Adjust for element size
      zIndex: 1000
    }}>
      {user ? (
        null
      ) : (
        <button 
          onClick={signInWithGoogle} 
          className="auth-button"
          style={buttonStyle}
          onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
          onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
        >
          Sign In
        </button>
      )}
    </div>
  );
};

const buttonStyle = {
  padding: '10px 20px', // Increased padding for a better look
  backgroundColor: '#4285F4', // Changed to a Google blue color
  color: '#fff', // Changed text color to white
  border: 'none', // Removed border for a cleaner look
  borderRadius: '5px', // Slightly increased border radius
  cursor: 'pointer',
  fontSize: '16px', // Increased font size
  transition: 'background-color 0.3s, transform 0.2s', // Added transform transition
};

export default Auth;