import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FaMicrophone, FaMicrophoneSlash, FaHighlighter, FaFolder, FaFolderOpen, FaFile, FaPlus, FaChevronRight, FaChevronDown, FaFileAlt, FaUpload, FaEdit } from 'react-icons/fa';
import axios from 'axios';
import { BlockMath, InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import { collection, addDoc, query, where, getDocs, getDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import Auth from './Auth';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import PowerPointUpload from './PowerPointUpload';
import PDFUpload from './PDFUpload';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import LatexRenderer from './LatexRenderer';

const RealTimeTranscription = () => {
  const [user] = useAuthState(auth);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [draggedItem, setDraggedItem] = useState(null);
  const [lastSaveTime, setLastSaveTime] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [transcript, setTranscript] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [keyPoints, setKeyPoints] = useState([]);
  const [openQuestionId, setOpenQuestionId] = useState(null);
  const [loadingQuestionId, setLoadingQuestionId] = useState(null);
  const transcriptRef = useRef(null);
  const [isHighlighting, setIsHighlighting] = useState(false);
  const isHighlightingRef = useRef(false);
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const audioContext = useRef(null);
  const analyser = useRef(null);
  const silenceDetector = useRef(null);
  const recordingStartTime = useRef(null);
  const [questionInterval] = useState(5);
  const [keyPointInterval] = useState(8);
  const [sessionId, setSessionId] = useState(null);
  const [userQuestion, setUserQuestion] = useState('');
  const [shouldAddNewline, setShouldAddNewline] = useState(false);
  const [isAutoScrolling, setIsAutoScrolling] = useState(true);
  const [fileStructure, setFileStructure] = useState([]);
  const [expandedFolders, setExpandedFolders] = useState({});
  const [newItemName, setNewItemName] = useState('');
  const [isCreatingNewItem, setIsCreatingNewItem] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [creatingItemType, setCreatingItemType] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [summaryContent, setSummaryContent] = useState('');
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [isUploadingPPTX, setIsUploadingPPTX] = useState(false);
  const [renamingItemId, setRenamingItemId] = useState(null);
  const [menuOpenItemId, setMenuOpenItemId] = useState(null);  // State to track which item's menu is open
  const [createMenuOpen, setCreateMenuOpen] = useState(false);
  const [fileMenuOpen, setFileMenuOpen] = useState(false);
  const [isUploadingPDF, setIsUploadingPDF] = useState(false);
  const [selectedFolderAction, setSelectedFolderAction] = useState(null);








  useEffect(() => {
    if (user) {
      fetchFileStructure();
    }
  }, [user]);

  const fetchFileStructure = async () => {
    if (!user) return;

    const foldersQuery = query(
      collection(db, "folders"),
      where("userId", "==", user.uid)
    );
    const filesQuery = query(
      collection(db, "files"),
      where("userId", "==", user.uid)
    );

    const [folderSnapshot, fileSnapshot] = await Promise.all([
      getDocs(foldersQuery),
      getDocs(filesQuery)
    ]);

    const folders = folderSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), type: 'folder' }));
    const files = fileSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), type: doc.data().type || 'file' }));

    const structure = buildFileStructure([...folders, ...files]);
    setFileStructure(structure);
  };

  const buildFileStructure = (items) => {
    const structure = [];
    const itemMap = {};

    items.forEach(item => {
      itemMap[item.id] = { ...item, children: [] };
    });

    items.forEach(item => {
      if (item.parentId && itemMap[item.parentId]) {
        itemMap[item.parentId].children.push(itemMap[item.id]);
      } else {
        structure.push(itemMap[item.id]);
      }
    });

    return structure;
  };
  const handlePPTXTranscriptGenerated = async (transcript) => {
    try {
      const newFileRef = await addDoc(collection(db, "files"), {
        name: "PowerPoint Transcript",
        type: "file",
        userId: user.uid,
        parentId: selectedFolderId,
        content: transcript.split('\n').map((text, index) => ({
          id: Date.now() + index,
          text,
          timestamp: new Date().toISOString(),
          highlighted: false
        })),
        questions: [],
        keyPoints: [],
        lastUpdated: new Date()
      });

      // Update file structure
      setFileStructure(prevStructure => {
        const newStructure = [...prevStructure];
        const newFile = {
          id: newFileRef.id,
          name: "PowerPoint Transcript",
          type: "file",
          userId: user.uid,
          parentId: selectedFolderId
        };

        if (selectedFolderId) {
          const addToFolder = (items) => {
            for (let item of items) {
              if (item.id === selectedFolderId) {
                item.children = [...(item.children || []), newFile];
                return true;
              }
              if (item.children && addToFolder(item.children)) {
                return true;
              }
            }
            return false;
          };
          addToFolder(newStructure);
        } else {
          newStructure.push(newFile);
        }

        return newStructure;
      });

      // Load the new file
      loadFile(newFileRef.id);
    } catch (error) {
      console.error("Error saving PowerPoint transcript:", error);
      alert("Failed to save PowerPoint transcript. Please try again.");
    } finally {
      setIsUploadingPPTX(false);
    }
  };
  const handlePDFTranscriptGenerated = async (transcript) => {
    try {
      const newFileRef = await addDoc(collection(db, "files"), {
        name: "PDF Transcript",
        type: "file",
        userId: user.uid,
        parentId: selectedFolderId,
        content: transcript,
        questions: [],
        keyPoints: [],
        lastUpdated: new Date()
      });

      // Update file structure
      setFileStructure(prevStructure => {
        const newStructure = [...prevStructure];
        const newFile = {
          id: newFileRef.id,
          name: "PDF Transcript",
          type: "file",
          userId: user.uid,
          parentId: selectedFolderId
        };

        if (selectedFolderId) {
          
          const addToFolder = (items) => {
            for (let item of items) {
              if (item.id === selectedFolderId) {
                item.children = [...(item.children || []), newFile];
                return true;
              }
              if (item.children && addToFolder(item.children)) {
                return true;
              }
            }
            return false;
          };
          addToFolder(newStructure);
        } else {
          newStructure.push(newFile);
        }

        return newStructure;
      });

      // Load the new file
      loadFile(newFileRef.id);
  } catch (error) {
    console.error("Error saving PDF transcript:", error);
    alert("Failed to save PDF transcript. Please try again.");
  } finally {
    setIsUploadingPDF(false);
  }
};

const toggleFolder = (folderId) => {
  setExpandedFolders(prev => ({ ...prev, [folderId]: !prev[folderId] }));
  setSelectedFolderId(folderId);
  loadFolderSummary(folderId);
  setSelectedFileId(null);
};

  const handleNewItemClick = (type) => {
    setIsCreatingNewItem(true);
    setCreatingItemType(type);
    setNewItemName('');
  };

  const createNewItem = async () => {
    if (newItemName.trim()) {
      const parentId = selectedFolderId;
      const newItem = {
        name: newItemName,
        userId: user.uid,
        parentId,
        type: creatingItemType,
        ...(creatingItemType === 'file' ? { content: '' } : {}),
        ...(creatingItemType === 'summary' ? { content: '', selectedFiles: [] } : {})
      };

      const docRef = await addDoc(collection(db, creatingItemType === 'folder' ? 'folders' : 'files'), newItem);
      newItem.id = docRef.id;

      setFileStructure(prev => {
        const updatedStructure = [...prev];
        const insertItem = (items) => {
          for (let item of items) {
            if (item.id === parentId) {
              const exists = item.children && item.children.some(child => child.id === newItem.id);
              if (!exists) {
                  item.children = [...(item.children || []), newItem];
              }
              return true;
            }
            if (item.children && insertItem(item.children)) {
              return true;
            }
          }
          return false;
        };

        if (parentId) {
          insertItem(updatedStructure);
        } else {
          updatedStructure.push(newItem);
        }
        return updatedStructure;
      });

      setIsCreatingNewItem(false);
      setNewItemName('');
      setCreatingItemType(null);
    }
  };

  const onDragStart = (e, item) => {
    e.stopPropagation();
    setDraggedItem(item);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = async (e, targetItem) => {
    e.preventDefault();
    e.stopPropagation();
    if (!draggedItem || draggedItem.id === targetItem.id) return;

    try {
        const draggedRef = doc(db, draggedItem.type === 'folder' ? 'folders' : 'files', draggedItem.id);
        await updateDoc(draggedRef, { parentId: targetItem ? targetItem.id : null }); // Update to set parentId to null if dropped outside

        // Update local state
        setFileStructure(prevStructure => {
            const newStructure = [...prevStructure];
            const removeItem = (items) => {
                for (let i = 0; i < items.length; i++) {
                    if (items[i].id === draggedItem.id) {
                        items.splice(i, 1);
                        return true;
                    }
                    if (items[i].children && removeItem(items[i].children)) {
                        return true;
                    }
                }
                return false;
            };

            const addItem = (items) => {
                if (targetItem) { // Only add to targetItem if it exists
                    for (let item of items) {
                        if (item.id === targetItem.id) {
                            item.children = [...(item.children || []), draggedItem];
                            return true;
                        }
                        if (item.children && addItem(item.children)) {
                            return true;
                        }
                    }
                } else { // If targetItem is null, add to root
                    newStructure.push(draggedItem);
                }
                return false;
            };

            removeItem(newStructure);
            addItem(newStructure);

            return newStructure;
        });

    } catch (error) {
        console.error("Error moving item:", error);
    }

    setDraggedItem(null);
};
const stopPropagation = (e) => {
  e.stopPropagation(); // Prevents the click event from bubbling up
};

const loadFile = async (fileId) => {
  try {
    const fileRef = doc(db, "files", fileId);
    const fileSnap = await getDoc(fileRef);
    
    if (fileSnap.exists()) {
      const data = fileSnap.data();
      if (data.type === 'summary') {
        setSummaryContent(data.content || '');
        setSelectedFiles(data.selectedFiles || []);
        setSelectedFileType('summary');
        // Reset transcript-related states
        setTranscript([]);
        setQuestions([]);
        setKeyPoints([]);
      } else {
        setTranscript(data.content || []);
        setQuestions(data.questions || []);
        setKeyPoints(data.keyPoints || []);
        setSelectedFileType('transcript');
        // Reset summary-related states
        setSummaryContent('');
        setSelectedFiles([]);
      }
      setSelectedFileId(fileId);
      setSelectedFolderId(null);

      setSessionId(Date.now().toString());
    } else {
      console.log("No such file!");
    }
  } catch (error) {
    console.error("Error loading file:", error);
  }
};
const generateFolderSummary = async () => {
  if (!selectedFolderId) {
    alert("Please select a folder to generate a summary.");
    return;
  }

  setIsSummaryLoading(true);

  try {
    // Fetch all files in the selected folder
    const filesQuery = query(
      collection(db, "files"),
      where("parentId", "==", selectedFolderId)
    );
    const filesSnapshot = await getDocs(filesQuery);
    const folderFiles = filesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    // Combine content of all files in the folder
    const combinedContent = await Promise.all(
      folderFiles.map(async (file) => {
        if (file.type === 'file') {
          return Array.isArray(file.content) 
            ? file.content.map(segment => segment.text).join('\n')
            : file.content;
        } else {
          return file.content || '';
        }
      })
    );


    const folderContent = combinedContent.join('\n\n');

    // Generate summary using OpenAI API
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o',
        messages: [
          { role: 'system', content: 'You are a helpful assistant that generates comprehensive and structured summaries. Use bullet points, tables, and short sentences where appropriate.' },
          { role: 'user', content: `Please provide a comprehensive summary of the following folder content:\n\n${folderContent}\n\nEnsure to:\n1. Detect and mention the document types\n2. Use bullet points, tables, and short sentences for clarity\n3. Include definitions where necessary\n4. For mathematical expressions, use LaTeX formatting` }
        ],
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        },
      }
    );

    const generatedSummary = response.data.choices[0].message.content.trim();
    setSummaryContent(generatedSummary);

    // Save the summary to Firestore
    const folderRef = doc(db, "folders", selectedFolderId);
    await updateDoc(folderRef, {
      summary: generatedSummary,
      lastUpdated: new Date()
    });

  } catch (error) {
    console.error('Error generating summary:', error);
    alert(`An error occurred while generating the summary: ${error.message}`);
  } finally {
    setIsSummaryLoading(false);
  }
};

const loadFolderSummary = async (folderId) => {
  setIsSummaryLoading(true);
  try {
    const folderRef = doc(db, "folders", folderId);
    const folderSnap = await getDoc(folderRef);

    if (folderSnap.exists()) {
      const data = folderSnap.data();
      if (data.summary) {
        setSummaryContent(data.summary);
        setSelectedFolderAction('summary'); // Automatically show the summary
      } else {
        setSummaryContent('');
        setSelectedFolderAction('summary'); // Show empty summary state
        console.log("No summary found for this folder.");
      }
    } else {
      console.log("No such folder!");
    }
  } catch (error) {
    console.error("Error loading folder summary:", error);
  } finally {
    setIsSummaryLoading(false);
  }
};


const processSegments = (segments) => {
  let fullText = '';
  let highlightedText = '';

  segments.forEach(segment => {
    fullText += segment.text + ' ';
    if (segment.highlighted) {
      highlightedText += '- ' + segment.text + '\n';
    }
  });

  return {
    text: fullText.trim(),
    highlightedText: highlightedText.trim()
  };
};

  const handleDelete = async (id, type) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete this ${type}?`);
    if (confirmDelete) {
        try {
            await deleteDoc(doc(db, type === 'folder' ? 'folders' : 'files', id));
            setFileStructure(prev => prev.filter(item => item.id !== id));
            console.log(`${type.charAt(0).toUpperCase() + type.slice(1)} deleted successfully`);
        } catch (error) {
            console.error(`Error deleting ${type}:`, error);
        }
    }
  };

  

  const saveToFirestore = async () => {
    if (!user || !selectedFileId) return;

    try {
      const fileRef = doc(db, "files", selectedFileId);
      await updateDoc(fileRef, {
        content: transcript,
        questions,
        keyPoints,
        lastUpdated: new Date(),
        userId: user.uid
      });
      setLastSaveTime(new Date().toLocaleTimeString());
      console.log("File updated successfully");
    } catch (e) {
      console.error("Error updating file: ", e);
    }
  };


  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);

      // Set up Web Audio API for silence detection
      audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioContext.current.createMediaStreamSource(stream);
      analyser.current = audioContext.current.createAnalyser();
      analyser.current.fftSize = 2048;
      source.connect(analyser.current);

      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };

      mediaRecorder.current.onstop = () => {
        processAudioChunk();
      };

      mediaRecorder.current.start();
      recordingStartTime.current = Date.now();
      setIsRecording(true);

      // Start silence detection
      detectSilence();

    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const detectSilence = useCallback(() => {
    const bufferLength = analyser.current.fftSize;
    const dataArray = new Uint8Array(bufferLength);
    let silenceCounter = 0;
    let pauseCounter = 0;
    const silenceThreshold = 10; // Adjust this value based on your needs
    const breakSpeachThreshold = 3000;
    const minRecordingDuration = 10000; // Minimum recording duration in milliseconds
    const maxRecordingDuration = 20000; // Maximum recording duration in milliseconds

    let shouldAddNewline = false;

    silenceDetector.current = setInterval(() => {
      const currentDuration = Date.now() - recordingStartTime.current;

      analyser.current.getByteTimeDomainData(dataArray);
      let sum = 0;
      for (let i = 0; i < bufferLength; i++) {
        sum += Math.abs(dataArray[i] - 128);
      }
      const average = sum / bufferLength;

      if (average < silenceThreshold) {
        silenceCounter += 50; // Interval is 50ms
        pauseCounter += 50;

        if (pauseCounter >= breakSpeachThreshold) {
          shouldAddNewline = true;
          pauseCounter = 0;
        }

        if (silenceCounter >= 500 && currentDuration >= minRecordingDuration) { // 0.5 seconds of silence
          if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
            mediaRecorder.current.stop();
            mediaRecorder.current.start();
            recordingStartTime.current = Date.now();
          }
          silenceCounter = 0;
        }
      } else { 
        silenceCounter = 0;
        pauseCounter = 0;
      }

      // Force stop and restart if max duration is reached
      if (currentDuration >= maxRecordingDuration) {
        if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
          mediaRecorder.current.stop();
          mediaRecorder.current.start();
          recordingStartTime.current = Date.now();
        }
      }
    }, 50);
  }, []);

  const stopRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
    }
    if (silenceDetector.current) {
      clearInterval(silenceDetector.current);
    }
    if (audioContext.current) {
      audioContext.current.close();
    }
    setIsRecording(false);
    saveToFirestore(); // Save data when recording stops
  };

  const processAudioChunk = async () => {
    if (audioChunks.current.length === 0) return;

    // Convert audio to WAV format
    const audioBlob = new Blob(audioChunks.current, { type: 'audio/webm' });
    audioChunks.current = [];

    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const audioBuffer = await audioContext.decodeAudioData(await audioBlob.arrayBuffer());
    const wavBuffer = audioBufferToWav(audioBuffer);
    const wavBlob = new Blob([wavBuffer], { type: 'audio/wav' });

    const formData = new FormData();
    formData.append('file', wavBlob, 'audio.wav');
    formData.append('model', 'whisper-1');
    formData.append('language', 'en');

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/audio/transcriptions',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      let transcribedText = response.data.text;
      if (shouldAddNewline) {
        transcribedText = '\n' + transcribedText;
        shouldAddNewline = false;
      }
      updateTranscript(transcribedText+" ");
    } catch (error) {
      console.error('Error transcribing audio:', error);
    }
  };

  // Add this helper function to convert AudioBuffer to WAV format
  function audioBufferToWav(buffer) {
    const numChannels = buffer.numberOfChannels;
    const sampleRate = buffer.sampleRate;
    const format = 1; // PCM
    const bitDepth = 16;

    const bytesPerSample = bitDepth / 8;
    const blockAlign = numChannels * bytesPerSample;

    const wavBuffer = new ArrayBuffer(44 + buffer.length * bytesPerSample);
    const view = new DataView(wavBuffer);

    /* RIFF identifier */
    writeString(view, 0, 'RIFF');
    /* RIFF chunk length */
    view.setUint32(4, 36 + buffer.length * bytesPerSample, true);
    /* RIFF type */
    writeString(view, 8, 'WAVE');
    /* format chunk identifier */
    writeString(view, 12, 'fmt ');
    /* format chunk length */
    view.setUint32(16, 16, true);
    /* sample format (raw) */
    view.setUint16(20, format, true);
    /* channel count */
    view.setUint16(22, numChannels, true);
    /* sample rate */
    view.setUint32(24, sampleRate, true);
    /* byte rate (sample rate * block align) */
    view.setUint32(28, sampleRate * blockAlign, true);
    /* block align (channel count * bytes per sample) */
    view.setUint16(32, blockAlign, true);
    /* bits per sample */
    view.setUint16(34, bitDepth, true);
    /* data chunk identifier */
    writeString(view, 36, 'data');
    /* data chunk length */
    view.setUint32(40, buffer.length * bytesPerSample, true);

    const samples = new Float32Array(buffer.length);
    const channelData = buffer.getChannelData(0);
    for (let i = 0; i < buffer.length; i++) {
      const offset = 44 + i * bytesPerSample;
      let sample = Math.max(-1, Math.min(1, channelData[i]));
      sample = sample < 0 ? sample * 0x8000 : sample * 0x7FFF;
      view.setInt16(offset, sample, true);
    }

    return wavBuffer;
  }

  function writeString(view, offset, string) {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  }

  const updateTranscript = useCallback((text) => {
    const timestamp = new Date().toISOString();
    setTranscript(prev => [...prev, { id: Date.now(), text, timestamp, highlighted: isHighlightingRef.current }]);
  }, []);

  const toggleHighlighting = useCallback(() => {
    setIsHighlighting(prev => {
      const newValue = !prev;
      isHighlightingRef.current = newValue;
      return newValue;
    });
  }, []);


  const toggleHighlight = async (id) => {
    const updatedTranscript = transcript.map(segment =>
      segment.id === id
        ? { ...segment, highlighted: !segment.highlighted }
        : segment
    );
    setTranscript(updatedTranscript);

    // Trigger autosave
    await saveToFirestore();
  };
  

  useEffect(() => {
    isHighlightingRef.current = isHighlighting;
  }, [isHighlighting]);
  useEffect(() => {
    const transcriptElement = transcriptRef.current;
    if (transcriptElement) {
      if (isAutoScrolling) {
        transcriptElement.scrollTop = transcriptElement.scrollHeight;
      }
    }
  }, [transcript, isAutoScrolling]);


  useEffect(() => {
    if(!isRecording) return;
    if (transcript.length > 0 && transcript.length % questionInterval === 0) {
      const lastElements = transcript.slice(-(questionInterval+1));

      generateQuestion(lastElements);
    }

    if (transcript.length > 0 && transcript.length % keyPointInterval === 0) {
      const lastElements = transcript.slice(-(keyPointInterval+1));
      generateKeyPoint(lastElements);
    }
  }, [transcript, questionInterval, keyPointInterval]);

  const handleScroll = () => {
    const transcriptElement = transcriptRef.current;
    if (transcriptElement) {
      const isAtBottom = transcriptElement.scrollHeight - transcriptElement.scrollTop === transcriptElement.clientHeight;
      setIsAutoScrolling(isAtBottom);
    }
  };



  const generateQuestion = async (lastElements) => {
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4o-mini',
          messages: [
            { role: 'system', content: 'You are a helpful assistant who understands the context of the conversation, understand the flaws in the transcript, and generates questions to help the user understand what is being talked about.' },
            {
              role: 'user',
              content: `Understand the context of the following transcript, and if you find an element that could be explained, or answered, or expanded in more details, generate one question that has not already been asked. Otherwise, answer "No question":

${lastElements.map(e => e.text).join(' ')}

Already asked questions: ${questions.map(q => q.text).join(', ')}
`
            }
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );

      const generatedQuestion = response.data.choices[0].message.content.trim();

      if (generatedQuestion && generatedQuestion !== "No question." && generatedQuestion !== "No question"&& !questions.some(q => q.text === generatedQuestion)) {
        const newQuestion = {
          id: Date.now(),
          text: generatedQuestion,
          transcriptId: lastElements[lastElements.length - 1].id, // Assign to last transcript ID
          answer: '',
          zIndex: questions.length, // Add zIndex for layering
          type: 'question',
        };
        setQuestions(prev => [...prev, newQuestion]);
      }

    } catch (error) {
      console.error('Error generating question:', error);
    }
  };
  const generateKeyPoint = async (lastElements) => {
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4o-mini',
          messages: [
            { role: 'system', content: 'You are a helpful assistant who understands the context of the conversation, understand the flaws in the transcript, and generates correct bullet points to summarize the main ideas of a conversation.' },
            {
              role: 'user',
              content: `Generate one or two very concise bullet points to summarize the main idea(s):


${lastElements.map(e => e.text).join(' ') }
`
            }
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );

      const generatedKeyPoint = response.data.choices[0].message.content.trim();
      

      if (generatedKeyPoint) {
        const newKeyPoint = {
          id: Date.now(),
          text: generatedKeyPoint,
          transcriptId: lastElements[lastElements.length - 1].id,
          zIndex: keyPoints.length,
          type: 'keyPoint',
        };
        setKeyPoints(prev => [...prev, newKeyPoint]);
      }

    } catch (error) {
      console.error('Error generating key point:', error);
    }
  };

  useEffect(() => {
    
    const updatePositions = () => {
      setQuestions(prevQuestions =>
        prevQuestions.map(question => ({
          ...question,
          position: calculatePosition(question.transcriptId),
        }))
      );
      setKeyPoints(prevKeyPoints =>
        prevKeyPoints.map(keyPoint => ({
          ...keyPoint,
          position: calculatePosition(keyPoint.transcriptId),
        }))
      );
    };

    const timeoutId = setTimeout(updatePositions, 0);

    return () => clearTimeout(timeoutId);
  }, [transcript]);

  const calculatePosition = (transcriptId) => {
    if (transcriptRef.current) {
      const transcriptElement = transcriptRef.current.querySelector(`#transcript-${transcriptId}`);
      if (transcriptElement) {
        const transcriptRect = transcriptRef.current.getBoundingClientRect();
        const elementRect = transcriptElement.getBoundingClientRect();
        return elementRect.top - transcriptRect.top;
      }
    }
    return 0;
  };

  const toggleQuestion = async (questionId) => {
    if (openQuestionId === questionId) {
      setOpenQuestionId(null);
    } else {
      setOpenQuestionId(questionId);
      const question = questions.find(q => q.id === questionId);
      if (question && !question.answer) {
        setLoadingQuestionId(questionId);
        await generateAnswer(questionId);
        setLoadingQuestionId(null);
      }
    }

    // Update zIndex to bring the clicked question to the top
    setQuestions(prevQuestions =>
      prevQuestions.map(q =>
        q.id === questionId
          ? { ...q, zIndex: questions.length }
          : { ...q, zIndex: q.zIndex > 0 ? q.zIndex - 1 : 0 }
      )
    );
  };


  const generateAnswer = async (questionId) => {
    const question = questions.find(q => q.id === questionId);
    if (!question) return;

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4o-mini',
          messages: [
            { role: 'system', content: 'You are a helpful assistant providing concise answers to questions.' },
            { role: 'user', content: `Please provide a brief answer to the following question: ${question.text}` }
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );

      const generatedAnswer = response.data.choices[0].message.content.trim();

      setQuestions(prevQuestions =>
        prevQuestions.map(q =>
          q.id === questionId
            ? { ...q, answer: generatedAnswer }
            : q
        )
      );
    } catch (error) {
      console.error('Error generating answer:', error);
    }
  };


  //RENDERING

  const renderWithMath = (text, highlighted, id) => {
    if (typeof text !== 'string') {
      console.error('Invalid text passed to renderWithMath:', text);
      return '';
    }
    const parts = text.split(/(\\\(.*?\\\)|\\\[.*?\\\])/);
    return parts.map((part, index) => {
      let content;
      if (part.startsWith('\\(') && part.endsWith('\\)')) {
        content = <InlineMath key={index}>{part.slice(2, -2)}</InlineMath>;
      } else if (part.startsWith('\\[') && part.endsWith('\\]')) {
        content = <BlockMath key={index}>{part.slice(2, -2)}</BlockMath>;
      } else {
        content = part;
      }
      return (
        <span
          key={`${id}-${index}`}
          onClick={() => toggleHighlight(id)}
        >
          {highlighted ? <mark>{content}</mark> : content}
        </span>
      );
    });
  };

  const handleClickOutside = (e) => {
    // Check if the click target is not a button, input, or FaFolder icon
    if (!e.target.closest('button') && !e.target.closest('input') && !e.target.closest('.fa-folder')) {
      setSelectedFolderId(null); // Reset selectedFolderId
    }
    setMenuOpenItemId(null);

  };

  


  const handleRename = async (item) => {
    if (newItemName.trim() === '') return;

    try {
      const itemRef = doc(db, item.type === 'folder' ? 'folders' : 'files', item.id);
      await updateDoc(itemRef, { name: newItemName });

      setFileStructure(prevStructure => {
        const updateItemName = (items) => {
          return items.map(i => {
            if (i.id === item.id) {
              return { ...i, name: newItemName };
            }
            if (i.children) {
              return { ...i, children: updateItemName(i.children) };
            }
            return i;
          });
        };
        return updateItemName(prevStructure);
      });

      setRenamingItemId(null);
      setNewItemName('');
    } catch (error) {
      console.error('Error renaming item:', error);
    }
  };



  const toggleMenu = (id) => {
    setMenuOpenItemId(menuOpenItemId === id ? null : id);
  };
  useEffect(() => {
    function handleOutsideClick(event) {
      if (!event.target.closest('.dropdown-menu')) {
        setCreateMenuOpen(false);
        setFileMenuOpen(false);
      }
    }
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, []);
  

  const renderFileStructure = (items, depth = 0) => {
    return items.map(item => (
      
      <div 
        key={`${item.id}-${depth}`} 
        style={{ marginLeft: `${depth * 20}px` }}
        draggable={true}
        onDragStart={(e) => onDragStart(e, item)}
        onDragOver={onDragOver}
        onDrop={(e) => onDrop(e, item)}
        onClick={(e) => e.stopPropagation()}
      >
        {renamingItemId === item.id ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="text"
              value={newItemName}
              onChange={(e) => setNewItemName(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleRename(item);
                }
              }}
              onBlur={() => handleRename(item)}
              autoFocus
            />
          </div>
        ) : (
          <div 
          onClick={(e) => {
            // Ensure that file selection doesn't happen when input/button is clicked
            const targetTag = e.target.tagName.toLowerCase();
            if (targetTag !== 'input' && targetTag !== 'button') {
              item.type === 'folder' ? toggleFolder(item.id) : loadFile(item.id);
            }
          }}
            style={{ 
              cursor: 'pointer', 
              display: 'flex', 
              alignItems: 'center',
              backgroundColor: (selectedFolderId === item.id || selectedFileId === item.id) ? '#e0e0e0' : 'transparent',
              padding: '5px',
            }}
          >
            {item.type === 'folder' ? (expandedFolders[item.id] ? <FaFolderOpen style={{marginLeft: '5px'}} /> : <FaFolder style={{marginLeft: '5px'}} />) : 
             item.type === 'summary' ? <FaFileAlt style={{marginLeft: '24px'}} /> : <FaFile style={{marginLeft: '24px'}} />}
            <span style={{ marginLeft: '5px' }}>{item.name}</span>
            
             <div style={{ display: 'flex', marginLeft: 'auto', position: 'relative' }}>
            <button onClick={(e) => { e.stopPropagation(); toggleMenu(item.id); }}>
              ⋮
            </button>

            {/* Dropdown Menu */}
            {menuOpenItemId === item.id && (
              <div style={{ position: 'absolute', top: '20px', right: '0px', backgroundColor: 'white', border: '1px solid #ccc', zIndex: 10 }}>
                <div 
                  onClick={(e) => { e.stopPropagation(); setRenamingItemId(item.id); setNewItemName(item.name); setMenuOpenItemId(null); }} 
                  style={{ padding: '5px', cursor: 'pointer' }}>
                  Rename
                </div>
                <div 
                  onClick={(e) => { e.stopPropagation(); handleDelete(item.id, item.type); setMenuOpenItemId(null); }} 
                  style={{ padding: '5px', cursor: 'pointer' }}>
                  Delete
                </div>
              </div>
            )}
          </div>
          </div>
        )}
        {item.type === 'folder' && expandedFolders[item.id] && item.children && renderFileStructure(item.children, depth + 1)}
      </div>
    ));
  };


  const renderFolderActionContent = () => {
    switch (selectedFolderAction) {
      case 'summary':
        return (
          <>
            <button 
              onClick={generateFolderSummary}
              disabled={isSummaryLoading}
              style={{
                padding: '10px',
                backgroundColor: 'blue',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                marginTop: '10px',
                width: '100%',
              }}
            >
              {isSummaryLoading ? 'Generating Summary...' : 'Generate Folder Summary'}
            </button>
            {summaryContent && (
              <div style={{
                marginTop: '20px',
                backgroundColor: '#f9f9f9',
                padding: '10px',
                borderRadius: '5px',
                maxHeight: '85vh',
                overflowY: 'auto',
              }}>
                <h4>Folder Summary:</h4>
                <LatexRenderer content={summaryContent} />
              </div>
            )}
          </>
        );
      case 'flashcards':
        return (
          <div style={{
            marginTop: '20px',
            backgroundColor: '#f9f9f9',
            padding: '10px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
            <h4>Flashcards</h4>
            <p>Coming soon...</p>
          </div>
        );
      case 'mockExams':
        return (
          <div style={{
            marginTop: '20px',
            backgroundColor: '#f9f9f9',
            padding: '10px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
            <h4>Mock Exams</h4>
            <p>Coming soon...</p>
          </div>
        );
      case 'quiz':
        return (
          <div style={{
            marginTop: '20px',
            backgroundColor: '#f9f9f9',
            padding: '10px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
            <h4>Quiz Questions</h4>
            <p>Coming soon...</p>
          </div>
        );
      case 'chatBox':
        return (
          <div style={{
            marginTop: '20px',
            backgroundColor: '#f9f9f9',
            padding: '10px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
            <h4>Chat Box</h4>
            <p>Coming soon...</p>
          </div>
        );
      default:
        return <p>Select a folder action from the sidebar.</p>;
    }
  };
  

  // CSS for the loading spinner
  const spinnerStyle = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    .spinner {
      border: 2px solid #f3f3f3;
      border-top: 2px solid #3498db;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      animation: spin 1s linear infinite;
    }
  `;

  return (
    <div style={{ 
      display: 'flex', 
      height: '100vh', 
      backgroundColor: '#f0f0f0',
      
    }}>
      <style>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        .spinner {
          border: 2px solid #f3f3f3;
          border-top: 2px solid #3498db;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          animation: spin 1s linear infinite;
        }
      `}</style>

      {/* Left sidebar with File Structure */}
      <div onClick={handleClickOutside} style={{
        width: '20%',
        height: '100%',
        overflowY: 'auto',
        borderRight: '1px solid #ccc',
        backgroundColor: 'white',
        paddingRight: '20px',
        paddingLeft: '20px',
      }}>
        <Auth />
        {user && (
          <>
            <h3>File Explorer</h3>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', position: 'relative' }}>
            {/* Create button */}
            <button 
              onClick={(e) => { 
                e.stopPropagation(); 
                setCreateMenuOpen(!createMenuOpen); 
              }} 
              style={{ flex: 1 }}
            >
              Create
            </button>
            
            {/* Create Dropdown Menu */}
            {createMenuOpen && (
              <div style={{ position: 'absolute', top: '30px', right: '0px', backgroundColor: 'white', border: '1px solid #ccc', zIndex: 10 }}>
                <div 
                  onClick={(e) => { 
                    e.stopPropagation(); 
                    handleNewItemClick('folder'); 
                    setCreateMenuOpen(false); 
                  }} 
                  style={{ padding: '5px', cursor: 'pointer' }}
                >
                  Folder
                </div>
                <div 
                  onClick={(e) => { 
                    e.stopPropagation(); 
                    setFileMenuOpen(!fileMenuOpen); 
                  }} 
                  style={{ padding: '5px', cursor: 'pointer' }}
                >
                  File
                  {fileMenuOpen && (
                    <div style={{ marginLeft: '10px', marginTop: '5px' }}>
                      <div 
                        onClick={(e) => { 
                          e.stopPropagation(); 
                          handleNewItemClick('transcript'); 
                          setFileMenuOpen(false); 
                          setCreateMenuOpen(false);
                        }} 
                        style={{ padding: '5px', cursor: 'pointer' }}
                      >
                        Transcript
                      </div>
                      
                      <div 
                        onClick={(e) => { 
                          e.stopPropagation(); 
                          setIsUploadingPPTX(true); 
                          setFileMenuOpen(false); 
                          setCreateMenuOpen(false);
                        }} 
                        style={{ padding: '5px', cursor: 'pointer' }}
                      >
                        Import File
                      </div>
                      <div 
                    onClick={(e) => { 
                      e.stopPropagation(); 
                      setIsUploadingPDF(true); 
                      setFileMenuOpen(false); 
                      setCreateMenuOpen(false);
                    }} 
                    style={{ padding: '5px', cursor: 'pointer' }}
                  >
                    Import PDF
                  </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

            {isUploadingPPTX && (
              <PowerPointUpload onTranscriptGenerated={handlePPTXTranscriptGenerated} />
            )}
            {isUploadingPDF && (
              <PDFUpload onTranscriptGenerated={handlePDFTranscriptGenerated} />
            )}

            {isCreatingNewItem && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input 
                  type="text" 
                  value={newItemName}
                  onChange={(e) => setNewItemName(e.target.value)}
                  placeholder={`New ${creatingItemType} name`}
                  style={{ width: '100%', marginBottom: '5px' }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      createNewItem();
                    }
                  }}
                />
                <button onClick={createNewItem}>Create {creatingItemType}</button>
              </div>
            )}
            <div style={{ marginTop: '10px' }}>
              {renderFileStructure(fileStructure)}
            </div>
            <button onClick={() => auth.signOut()} style={{ marginTop: '20px', width: '100%' }}>
              Sign Out
            </button>
          </>
        )}
      </div>


      {/* Folder Actions Sidebar */}
      {selectedFolderId && (
        <div style={{
          width: '20%',
          height: '100%',
          overflowY: 'auto',
          borderRight: '1px solid #ccc',
          backgroundColor: 'white',
          padding: '20px',
        }}>
          <h3>Folder Actions</h3>
          <button 
            onClick={() => setSelectedFolderAction('summary')}
            style={{
              padding: '10px',
              backgroundColor: selectedFolderAction === 'summary' ? 'blue' : 'lightblue',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              marginTop: '10px',
              width: '100%',
            }}
          >
            Summary
          </button>
          <button 
            onClick={() => setSelectedFolderAction('flashcards')}
            style={{
              padding: '10px',
              backgroundColor: selectedFolderAction === 'flashcards' ? 'blue' : 'lightblue',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              marginTop: '10px',
              width: '100%',
            }}
          >
            Flashcards
          </button>
          <button 
            onClick={() => setSelectedFolderAction('mockExams')}
            style={{
              padding: '10px',
              backgroundColor: selectedFolderAction === 'mockExams' ? 'blue' : 'lightblue',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              marginTop: '10px',
              width: '100%',
            }}
          >
            Quiz Questions
          </button>
          <button 
            onClick={() => setSelectedFolderAction('quiz')}
            style={{
              padding: '10px',
              backgroundColor: selectedFolderAction === 'quiz' ? 'blue' : 'lightblue',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              marginTop: '10px',
              width: '100%',
            }}
          >
            Mock Exams
          </button>
          <button 
            onClick={() => setSelectedFolderAction('chatBox')}
            style={{
              padding: '10px',
              backgroundColor: selectedFolderAction === 'chatBox' ? 'blue' : 'lightblue',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              marginTop: '10px',
              width: '100%',
            }}
          >
            Chat Box
          </button>
        </div>
      )}


       {/* Main content area */}
       <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}>
        {user ? (
          selectedFileId ? (
            <>
              {selectedFileType === 'transcript' && (
                <>
                  <div style={{
                    padding: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '20px'
                  }}>
                    <button
                      onClick={isRecording ? stopRecording : startRecording}
                      style={{
                        padding: '10px',
                        backgroundColor: isRecording ? 'red' : 'blue',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      {isRecording ? <FaMicrophoneSlash style={{marginRight: '5px'}} /> : <FaMicrophone style={{marginRight: '5px'}} />}
                      {isRecording ? ' Stop Recording' : ' Start Recording'}
                    </button>
                    <button
                      onClick={toggleHighlighting}
                      style={{
                        padding: '10px',
                        backgroundColor: isHighlighting ? 'yellow' : 'gray',
                        color: 'black',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <FaHighlighter style={{marginRight: '5px'}} />
                      {isHighlighting ? ' Stop Highlighting' : ' Start Highlighting'}
                    </button>
                  </div>

                  <div style={{ 
                    flex: 1, 
                    display: 'flex', 
                    justifyContent: 'center',
                    position: 'relative',
                    overflowY: 'hidden'
                  }}>
                    {/* Transcript in the middle */}
                    <div 
                      ref={transcriptRef}
                      onScroll={handleScroll}
                      style={{ 
                        width: '30%', 
                        height: '100%',
                        backgroundColor: 'white', 
                        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                        padding: '0.2in',
                        overflowY: 'auto',
                        position: 'relative',
                        textAlign: 'justify',
                        fontSize: '16px',
                      }}
                    >
                      <h2>Transcript:</h2>
                      <div style={{ whiteSpace: 'pre-line', marginBottom:'20%' }}>
                        {transcript.map((segment) => (
                          <span 
                            key={segment.id} 
                            id={`transcript-${segment.id}`}
                          >
                            {renderWithMath(segment.text || '', segment.highlighted, segment.id)}
                          </span>
                        ))}
                      </div>
                    </div>

                    {/* Combined Questions and Key Points on the right */}
                    <div style={{ 
                      width: '40%', 
                      height: '95%', 
                      overflowY: 'auto', 
                      padding: '20px', 
                    }}>
                      {[...questions, ...keyPoints]
                        .sort((a, b) => (a.timestamp || a.id) - (b.timestamp || b.id))
                        .map((item) => (
                          <div 
                            key={item.id} 
                            style={{
                              backgroundColor: item.type === 'question' ? '#ebf7ff' : '#f0fff0',
                              padding: '10px',
                              marginBottom: '10px',
                              borderRadius: '5px',
                              boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                              fontSize: item.type === 'question' ? '14px' : '16px',
                              cursor: item.type === 'question' ? 'pointer' : 'default',
                              textAlign: 'justify',
                            }}
                            onClick={item.type === 'question' ? () => toggleQuestion(item.id) : undefined}
                          >
                            {renderWithMath(item.text)}
                            {item.type === 'question' && openQuestionId === item.id && (
                              <div style={{
                                marginTop: '10px',
                                padding: '10px',
                                backgroundColor: 'white',
                                borderRadius: '5px',
                                fontSize: '13px',
                                minHeight: '30px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                overflowX: 'auto',
                                whiteSpace: 'pre-wrap',
                                maxWidth: '100%',
                              }}>
                                {loadingQuestionId === item.id ? (
                                  <div className="spinner"></div>
                                ) : (
                                  <div style={{ width: '100%' }}>{renderWithMath(item.answer)}</div>
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>

                  {/* User question input */}
                  <div style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
                    <input
                      type="text"
                      value={userQuestion}
                      onChange={(e) => setUserQuestion(e.target.value)}
                      placeholder="Ask a question about the transcript..."
                      style={{ flex: 1, padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                    />
                    <button
                      onClick={() => {
                        if (userQuestion.trim()) {
                          const newQuestion = {
                            id: Date.now(),
                            text: userQuestion,
                            transcriptId: transcript.length > 0 ? transcript[transcript.length - 1].id : null,
                            answer: '',
                            color: 'lightgreen',
                            type: 'question',
                          };
                          setQuestions(prev => [...prev, newQuestion]);
                          setUserQuestion('');
                        }
                      }}
                      style={{ marginLeft: '10px', padding: '10px', backgroundColor: 'green', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                    >
                      Submit Question
                    </button>
                  </div>
                </>
              )}
</>
          ) : selectedFolderId ? (
            <div style={{ padding: '20px' }}>
              {renderFolderActionContent()}
            </div>
              

              

          ) : (
            <p style={{ textAlign: 'center', fontSize: '20px', color: '#333' }}>Please select a file to start transcribing or view summary.</p>
          )
        ) : (
          <p style={{ textAlign: 'center', fontSize: '20px', color: '#333' }}>Please sign in to start recording or view summaries.</p>
        )}
      </div>
      
    </div>
  );
};

export default RealTimeTranscription;